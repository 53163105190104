import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				I nostri servizi | Esplora i servizi del Martesana Paintball
			</title>
			<meta name={"description"} content={"Esperienze di Paintball su misura per ogni giocatore"} />
			<meta property={"og:title"} content={"I nostri servizi | Esplora i servizi del Martesana Paintball"} />
			<meta property={"og:description"} content={"Esperienze di Paintball su misura per ogni giocatore"} />
			<meta property={"og:image"} content={"https://exilovatebix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://exilovatebix.com/img/324234.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://exilovatebix.com/img/324234.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://exilovatebix.com/img/324234.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://exilovatebix.com/img/324234.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://exilovatebix.com/img/324234.png"} />
			<meta name={"msapplication-TileImage"} content={"https://exilovatebix.com/img/324234.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://exilovatebix.com/img/3.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
						Il nostro mondo di Paintball
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
						Al Martesana Paintball, ci impegniamo a fornire un'esperienza di paintball completa ed entusiasmante, adatta a soddisfare le esigenze di ogni giocatore. Che si tratti di un appassionato esperto o di un nuovo giocatore, la nostra gamma di servizi garantisce che ogni visita sia all'insegna del divertimento, della strategia e della sicurezza.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Image
						src="https://exilovatebix.com/img/4.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Eventi di gruppo personalizzati
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Pacchetti aziendali: Migliorate la coesione del team con scenari di gioco strategici.
							<br />
							<br />
   Compleanno: Festeggiate con giochi ricchi di azione e sistemazioni speciali.
							<br />
							<br />
   Team building: Promuovere il lavoro di squadra e la comunicazione con sfide personalizzate.

						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Corsi introduttivi: Imparare le basi della sicurezza, dell'attrezzatura e delle regole del gioco.
							<br />
							<br />
   Giochi guidati: Giocate a fianco di personale esperto che fornisce suggerimenti e indicazioni.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Gioco tattico avanzato
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Laboratori di abilità: Migliorare le manovre tattiche e la precisione di tiro.
							<br />
							<br />
   Campionati competitivi: Partecipate ai tornei locali e ai campionati stagionali per un gioco più intenso.

						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Pacchetti di noleggio: Dalle maschere ai pennarelli, tutto il necessario per iniziare a giocare.
							<br />
							<br />
							
   Pro Shop: Acquistate il vostro equipaggiamento dalla nostra selezione di attrezzature delle migliori marche.

						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Image
						src="https://exilovatebix.com/img/5.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://exilovatebix.com/img/6.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
						Per saperne di più e partecipare
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
						Siete interessati a saperne di più sui nostri servizi o a prenotare la vostra prossima avventura di paintball? Visitate il Martesana Paintball per conoscere da vicino le nostre strutture e le nostre offerte. Il nostro staff è pronto ad assistervi nella pianificazione della perfetta uscita di paintball, assicurandovi un'esperienza su misura che soddisfi tutte le vostre esigenze.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});